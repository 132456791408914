import React from 'react';
import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { Heading } from '../../../../components/Heading';
import { BusinessComponentAllocationMatrix } from './components/BusinessComponentAllocationMatrix';
import { BusinessComponentSectionDescription } from './components/BusinessComponentSectionDescription';

export function BusinessComponentSection({ data, surveyType }) {
  return (
    <View>
      <Heading name="Business Component" />
      <BusinessComponentSectionDescription data={data} surveyType={surveyType} />
      <BusinessComponentAllocationMatrix data={data} />
    </View>
  );
}

BusinessComponentSection.propTypes = {
  data: PropTypes.object.isRequired,
  surveyType: PropTypes.string.isRequired,
};

export default BusinessComponentSection;
