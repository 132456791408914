import { API, Auth } from 'aws-amplify';
import { set } from 'lodash';
import axios from 'axios';
import { DEFAULT_BUSINESS_ID, DEFAULT_COUNTRY } from '../config/api_constants';

export const downloadFileFromURL = async ({ presignedURL, fileName }) => {
  const response = await axios.get(presignedURL, { responseType: 'blob' });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

async function postWithContext(API_G, apiEndpoint, payload, entityId) {
  let newPayload = payload;
  if (!newPayload) {
    newPayload = {};
  }
  const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
  set(newPayload, ['body', 'accessToken'], accessToken);
  set(newPayload, ['body', 'clientId'], DEFAULT_BUSINESS_ID + (entityId === undefined ? '' : entityId));
  set(newPayload, ['body', 'country'], DEFAULT_COUNTRY);
  return API.post(API_G, apiEndpoint, newPayload);
}
export default postWithContext;
