import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@amzn/awsui-components-react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { isNull } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { SurveyPDFTemplate } from './templates/SurveyPDFTemplate/SurveyPDFTemplate';
import getSurveyData from './templates/SurveyPDFTemplate/utils/DataUtils';
import { setPageNotification } from '../with_page/redux/reducer';
import { NOTIFICATION_TYPE_ERROR } from '../with_page/notifications/constants';

const PDFGenerator = props => {
  const { record, isVisible, pushNotification, entityId } = props;
  const [startPdfGeneration, setStartPdfGeneration] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataForSurveyPdfTemplate, setDataForSurveyPdfTemplate] = useState({});

  useEffect(() => {
    if (!isVisible) {
      setStartPdfGeneration(false);
    }
  }, [isVisible]);

  const stopPDFGeneration = () => {
    setStartPdfGeneration(false);
  };

  const startPDFGenerationProcess = async () => {
    setIsLoading(true);
    const surveyData = await getSurveyData(record, entityId);
    if (!isNull(surveyData)) {
      setDataForSurveyPdfTemplate(surveyData);
      // kept here to resolve the sync issue of states.
      setIsLoading(false);
      setStartPdfGeneration(true);
    } else {
      pushNotification({
        type: NOTIFICATION_TYPE_ERROR,
        content: 'While downloading, something went wrong. Please try again later.',
        id: uuidv4(),
      });
      setIsLoading(false);
    }
  };

  const confirmPDFDownload = blob => {
    saveAs(blob, `${dataForSurveyPdfTemplate.surveyName}-SurveyDetails.pdf`);
    stopPDFGeneration();
  };
  return (
    <div>
      {startPdfGeneration === false && (
        <Button loading={isLoading} disabled={!isVisible} onClick={startPDFGenerationProcess}>
          Download PDF
        </Button>
      )}
      {isVisible && startPdfGeneration === true && (
        <PDFDownloadLink document={<SurveyPDFTemplate data={dataForSurveyPdfTemplate} />}>
          {({ blob, loading }) =>
            loading ? (
              <Button loading={isLoading}>Download PDF</Button>
            ) : (
              <Button onClick={confirmPDFDownload(blob)} />
            )
          }
        </PDFDownloadLink>
      )}
    </div>
  );
};

PDFGenerator.propTypes = {
  record: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  pushNotification: PropTypes.func.isRequired,
  entityId: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pushNotification: setPageNotification,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(PDFGenerator);
