import React from 'react';
import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { Heading } from '../../../../../components/Heading';
import { EmployeeActivityMatrixDescription } from './components/EmployeeActivityMatrixDescription';
import { EmployeeActivityDualAllocationMatrix } from './components/EmployeeActivityDualAllocationMatrix';

export function EmployeeActivityDualAllocationMatrixSection({ data }) {
  return (
    <View>
      <Heading name="Activity Allocation" />
      <EmployeeActivityMatrixDescription />
      <EmployeeActivityDualAllocationMatrix data={data} />
    </View>
  );
}

EmployeeActivityDualAllocationMatrixSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default EmployeeActivityDualAllocationMatrixSection;
