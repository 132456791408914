import React, { useEffect, useState } from 'react';
import { Button } from '@amzn/awsui-components-react';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash/lang';
import { triggerReportRefreshAction } from '../../report_table/redux/TriggerReportRefresh';
import {
  getStudyPeriod,
  isActionDisabledForStudyPeriod,
} from '../../../../../common/constants/study_period';
import {
  getTriggerAllReportRefreshRequest,
  REPORT_REFRESH_STATUS,
} from '../../../constants/api_constants';
import {
  getUpdateStatus,
  isAnyReportInProgress,
  isReportIdDoesNotSame,
} from '../../utils/UpdateStatusUtils';
import addContextInPayloadObjects from '../../utils/ApiUtils';

const ReportsCardsFilterActions = props => {
  const {
    triggerReportRefreshBegin,
    studyPeriod,
    reportRefreshStatusData,
    reportData,
    selectedReportCards,
    surveyType,
    qreClassification,
  } = props;
  const onReportRefreshHandler = () => {
    triggerReportRefreshBegin({
      body: {
        reports: addContextInPayloadObjects(
          getTriggerAllReportRefreshRequest(),
          studyPeriod,
          surveyType,
          qreClassification,
        ),
      },
    });
  };
  const reportType = get(selectedReportCards[0], ['id'], '');
  const [isRefreshButtonDisabled, setRefreshButtonDisabled] = useState(false);
  useEffect(() => {
    if (reportData && reportRefreshStatusData) {
      const updateStatus = getUpdateStatus(reportRefreshStatusData, reportData, reportType);
      const isReportIdsDoesNotMatch =
        isReportIdDoesNotSame(reportRefreshStatusData, reportData, reportType) &&
        !isEqual(updateStatus, REPORT_REFRESH_STATUS.Failed);
      const anyReportInProgress = isAnyReportInProgress(reportRefreshStatusData);
      setRefreshButtonDisabled(isReportIdsDoesNotMatch || anyReportInProgress);
    }
  }, [reportData, reportRefreshStatusData, reportType]);
  return (
    <div>
      <Button
        iconName="refresh"
        variant="icon"
        onClick={onReportRefreshHandler}
        disabled={isRefreshButtonDisabled || isActionDisabledForStudyPeriod(studyPeriod)}
      >
        Refresh
      </Button>
    </div>
  );
};

ReportsCardsFilterActions.propTypes = {
  triggerReportRefreshBegin: PropTypes.func.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  reportRefreshStatusData: PropTypes.object.isRequired,
  reportData: PropTypes.object.isRequired,
  selectedReportCards: PropTypes.array.isRequired,
  surveyType: PropTypes.string,
  qreClassification: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    studyPeriod: getStudyPeriod(state),
    reportRefreshStatusData: get(state, 'entities.getReportRefreshStatus.data', {}),
    reportData: get(state, 'entities.reporting.data', {}),
    surveyType: get(state, 'studyType.surveyType'),
    qreClassification: get(state, 'studyType.qreClassification'),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      triggerReportRefreshBegin: triggerReportRefreshAction.BEGIN,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReportsCardsFilterActions);
