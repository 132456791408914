import React from 'react';
import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { AllocationMatrix } from '../../../common/components/AllocationMatrix';

export function EmployeeActivityDualAllocationMatrix({ data }) {
  const activityDualAllocation = get(data, 'userResponse.ActivityDualAllocation.data', {});
  const defaultActivityList = get(data, 'userResponse.Activity.data.defaultItems', []);
  const selectedActivityList = get(data, 'userResponse.Activity.data.selectedActivities', []);
  const totalActivitiesList = [...defaultActivityList, ...selectedActivityList];
  const activityMapping = {};

  // Create activity mapping
  totalActivitiesList.forEach(activity => {
    const activityId = get(activity, 'id', '');
    activityMapping[activityId] = get(activity, 'name', '');
  });

  // Create employee mapping
  const employeeMapping = {};
  const employeeData = get(data, 'employeeData', []);
  if (!isNull(employeeData)) {
    employeeData.forEach(employee => {
      const employeeId = get(employee, 'recordId', '');
      employeeMapping[employeeId] = get(employee, 'employeeName', '');
    });
  }

  // Create separate matrices for each employee's projects
  return Object.entries(activityDualAllocation).map(([employeeId, projectData]) => {
    // For each project of this employee
    return Object.entries(projectData).map(([projectId, allocationData]) => (
      <AllocationMatrix
        key={`${employeeId}-${projectId}`}
        data={{ [employeeId]: allocationData }}
        projectActivityNameMapping={activityMapping}
        entityNameMapping={employeeMapping}
      />
    ));
  });
}

EmployeeActivityDualAllocationMatrix.propTypes = {
  data: PropTypes.object.isRequired,
};

export default EmployeeActivityDualAllocationMatrix;
